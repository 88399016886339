import React from "react";

const Header = () => {
  return (
    <>
      <h1 className="text-3xl font-bold mb-4 text-red-400">Weather Forecast</h1>
    </>
  );
};

export default Header;
